import { CommonModule } from '@angular/common'
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import {
    ReactiveFormsModule,
    FormGroup,
    FormBuilder,
    Validators,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatToolbarModule } from '@angular/material/toolbar'
import { TranslocoModule } from '@ngneat/transloco'
import {
    PhotoInterface,
    PhotoType,
} from 'src/app/shared/interfaces/Image.interface'
import Utils from 'src/app/shared/services/utils'

@Component({
    standalone: true,
    selector: 'app-photo-upload',
    templateUrl: './photo-upload.component.html',
    styleUrls: ['./photo-upload.component.scss'],
    imports: [
        MatInputModule,
        MatListModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        CommonModule,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class PhotoUploadComponent implements OnInit {
    @Output() sendPhotosToParentForm: EventEmitter<PhotoInterface[]> =
        new EventEmitter()
    filesToUpload: PhotoInterface[] = []
    errorMessage: string = ''

    @Input() fileLimit: number = 1
    @Input() bucketId: string
    @Input() photoType: PhotoType
    @Input() isRequired: boolean = false
    @Input() title: string = 'Photo Upload'
    @Input() parentForm: FormGroup | null = null
    @Input() initFiles: PhotoInterface[] = []
    @Input() multipleFileUpload: boolean = false

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        // Initialize with provided files
        if (this.initFiles && this.initFiles.length > 0) {
            this.filesToUpload = [...this.initFiles]
            this.sendPhotosToParentForm.emit(this.filesToUpload)
        }

        // Add control to parent form if provided
        if (this.parentForm) {
            this.parentForm.addControl(
                'uploadPhotos',
                this.fb.control(
                    null,
                    this.isRequired ? Validators.required : []
                )
            )
        }
    }

    selectedFiles(event: Event): void {
        const input = event.target as HTMLInputElement
        if (!input?.files?.length) {
            this.errorMessage = 'No files selected.'
            return
        }

        const files = input.files
        this.errorMessage = ''

        if (!this.multipleFileUpload) {
            this.filesToUpload = [] // Ensure previous files are cleared
        }

        if (files.length > this.fileLimit) {
            this.errorMessage = `You can upload up to ${this.fileLimit} photo(s).`
            return
        }

        Array.from(files).forEach((file) => {
            if (Utils.convertBytesToMegaByte(file.size) > 5) {
                this.errorMessage = `File ${file.name} exceeds 5 MB.`
                return
            }

            const reader = new FileReader()
            reader.onloadend = () => {
                const photo: PhotoInterface = {
                    id: Utils.generateUniqueId(),
                    bucketId: this.bucketId,
                    name: file.name,
                    photo: file,
                    dataUrl: reader.result,
                    photoType: this.photoType,
                }

                this.filesToUpload = [...this.filesToUpload, photo] // Avoid direct mutation

                this.sendPhotosToParentForm.emit(this.filesToUpload)
            }
            reader.onerror = () => {
                this.errorMessage = `Failed to load photo: ${file.name}`
            }
            reader.readAsDataURL(file)
        })
    }
}
