<mat-card class="photo-upload-card" *transloco="let t; read: 'photoUpload'">
    <mat-card-header>
      <mat-card-title>{{ t('title') }}</mat-card-title>
      <mat-card-subtitle>{{ t('subtitle') }}</mat-card-subtitle>
    </mat-card-header>
  
    <mat-card-content>
      <div class="row">
        <div class="button-container">
          <button mat-button color="primary" (click)="photoInput.click()" type="button">
            <mat-icon>attach_file</mat-icon>
            {{ t('selectPhotos') }}
          </button>
          <input
            type="file"
            #photoInput
            accept="image/*"
            (change)="selectedFiles($event)"
            [multiple]="multipleFileUpload"
            hidden
          />
        </div>
      </div>
      <div class="preview-container" *ngIf="filesToUpload.length > 0">
        <div class="preview-item" *ngFor="let photo of filesToUpload">
          <img
            [src]="photo.dataUrl"
            [alt]="photo.photo.name"
            class="preview-image"
          />
        </div>
      </div>
      <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    </mat-card-content>
  </mat-card>
  