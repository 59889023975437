import { Models } from 'appwrite'
import { DateTime } from 'luxon'

export interface NewsInterface {
    title: string
    description: string
    masjid_id: string
    image_id: string | null
    video_id: string | null
    news_id: string | null
    created_date: DateTime | null
    updated_date: DateTime | null
    imageUrl?: string
    videoUrl?: string
}

export function convertNewsDocumentToInterface(
    document: Models.Document
): NewsInterface {
    return {
        title: document['title'] as string,
        description: document['description'] as string,
        masjid_id: document['masjid_id'] as string,
        news_id: document.$id,
        image_id: document['image_id'] as string,
        video_id: document['video_id'] as string,
        created_date: DateTime.fromISO(document.$createdAt),
        updated_date: DateTime.fromISO(document.$updatedAt),
    } as NewsInterface
}
